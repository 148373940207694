






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IFormParams } from '@/interfaces/IFormParams';

import BackButton from '@/components/ui/BackButton.vue';

@Component({
    components: {
        BackButton
    }
})
export default class Help extends Vue {
    subject = '';
    message = '';
    followUp = false;
    loading = false;

    subjects = {
        account: 'Account / Billing',
        bugs: 'Bugs',
        comments: 'Comments / Concerns',
        question: 'General Question',
        other: 'Other'
    }

    get selectItems() {
        return Object.values(this.subjects);
    }

    get disabled() {
        return !this.message;
    }

    get email() {
        return this.$store.state.user.profile.email;
    }

    submitForm() {
        // call service
        const params: IFormParams = {
            subject: this.subject,
            message: this.message,
            email: this.email,
            followUp: this.followUp
        };

        this.loading = true;

        this.$store.dispatch('submitForm', params)
        .then(() => {
            this.initForm();
        })
        .finally(() => {
            this.loading = false;
        });
    }

    initForm() {
        this.subject = this.subjects.question;
        this.message = '';
        this.followUp = false;
    }

    mounted() {
        this.initForm();
    }
}
